/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useAuth } from 'react-omnitech-api'
import { useLink } from '../../../hook'
import AccountHomeView from './account-home-view'

function AccountHomeController() {
  // prepare hook
  const { auth } = useAuth()
  const { navigate } = useLink()

  /**
   * when the page is loaded
   * without user logged in, redirect to login page
   */
  useEffect(() => {
    if (!auth.userId) {
      navigate(
        '/login/',
        {
          state: {
            redirectUrl: '/account/profile/',
          },
          replace: true,
        },
      )
    }

    navigate('/account/profile/')
  }, [])

  return (
    <AccountHomeView />
  )
}

export default AccountHomeController
